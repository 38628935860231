@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@700&family=Lato:wght@300&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav {
  background-color: #3498db;
  display: flex;
  color: white;
  padding: 0 1rem;
  justify-content: space-between;
  align-items: stretch;
}

.site-name {
  color: white;
  text-decoration: none;
  font-family: 'Be Vietnam Pro', sans-serif;
  font-size: 2rem;
  padding: 4px;
}

.nav a {
  color: white;
  text-decoration: none;
  height: 100%;
  display: flex;
}

.nav ul {
  list-style: none;
  padding: 0;
  font-family: 'Be Vietnam Pro', sans-serif;
  display: flex;
  gap: 2rem;
}

.textbox {
  padding: 0 1 rem;
}

.page {
  margin: 50px;
  font-family: 'Lato', sans-serif;
}

.page h1 {
  font-family: 'Be Vietnam Pro', sans-serif;
}

.document-text {
  background-color: #ecf0f1;
  border: 2px transparent;
  border-radius: 10px;
  padding: 1em;
  resize: none;
}

.translate-button {
  background: #3498db;
  border: none;
  font-family: 'Be Vietnam Pro', sans-serif;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  color: white;
  padding: 16px;
  border-radius: 14px;
  transition: 0.3s;
  margin: 15px;
}

.translate-button:hover {
  color: white;
  background: #62b5f0;
  cursor: pointer;
}

.word-textarea {
  font-size: 1rem;
  background-color: #C6DDED;
  resize: none;
  border: #C6DDED;
  border-radius: 4px;
  padding: 5px;
}

.container {
  display: flex;
}

.words-container {
  align-items: center;
  margin: 20px;
  text-align: center;
}

.learned-words-textarea {
  background: #D9D9D9;
  border: transparent;
  border-radius: 10px;
  resize: none;
  padding: 10px;
}

.headline {
  font-size: 4rem;
  color: #3498db;
}

.caption {
  font-size: 1.5rem;
  color: #7C7C7C;
  font-family: 'Be Vietnam Pro', sans-serif;
}

.get-started-button {
  border: 1px solid #3498db;
  color: white;
  background: #3498db;
  
  font-family: 'Be Vietnam Pro', sans-serif;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  padding: 16px;
  border-radius: 14px;
  margin-top: 4rem;
  cursor: pointer;
  transition: 0.6s;

}

.get-started-button:hover {
  color: grey;
  background: none;
}

.sign-out-btn {
  background: #3498db;
  font-family: 'Be Vietnam Pro', sans-serif;
  text-align: center;
  text-decoration: none;
  border: none;
  font-size: 16px;
  color: white;
  padding: 12px;
  border-radius: 14px;
}

.profile-pic {
  margin-left: auto;
  height: auto;
}

.upload-file-btn {
  background: #3498db;
  font-family: 'Be Vietnam Pro', sans-serif;
  text-align: center;
  text-decoration: none;
  border: none;
  font-size: 14px;
  color: white;
  padding: 6px;
  border-radius: 14px;
  cursor: pointer;
}

.label-text {
  margin:0px 
}

.login-with-google-btn {
  transition: background-color .3s, box-shadow .3s;
    
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
  
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-size: 25px 25px;
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
  
  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
  }
  
  &:active {
    background-color: #eeeeee;
  }
  
  &:focus {
    outline: none;
    box-shadow: 
      0 -1px 0 rgba(0, 0, 0, .04),
      0 2px 4px rgba(0, 0, 0, .25),
      0 0 0 3px #c8dafc;
  }
  
  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
  }
}